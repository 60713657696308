<template>
  <div>
    <transition mode="out-in" name="sf-collapse-top">
      <div
        v-if="notifications.length > 0"
        class="bg-[#ebf1cb] py-3"
        :class="{
          'kft-notifications--info': notifications[0].type === 'INFO',
          'kft-notifications--success': notifications[0].type === 'SUCCESS',
          'kft-notifications--warning': notifications[0].type === 'WARNING',
          'kft-notifications--error': notifications[0].type === 'ERROR',
        }"
        data-test-id="notifications__container"
      >
        <div class="flex max-w-screen-xl m-auto px-4 justify-between">
          <div
            v-if="notifications[0].htmlContent"
            v-html="notifications[0].htmlContent"
          ></div>
          <template v-else>
            <span>
              <i
                :class="`bi ${iconByNotificationType[notifications[0].type]}`"
              />
            </span>

            <span>{{ notifications[0].message }}</span>
          </template>

          <span class="justify-self-end">
            <button @click="handleCloseNotification">
              <IconXLg class="w-5" />
            </button>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import type { KftNotificationType } from '~/composables/useNotifications'

export default {
  name: 'KftNotifications',

  setup() {
    const { notifications, addNotification, handleCloseNotification } =
      useNotifications()

    const iconByNotificationType: Record<KftNotificationType, string> = {
      INFO: 'bi-info-circle-fill',
      SUCCESS: 'bi-check-circle-fill',
      WARNING: 'bi-exclamation-circle-fill',
      ERROR: 'bi-exclamation-triangle-fill',
    }

    return {
      iconByNotificationType,
      notifications,

      addNotification,
      handleCloseNotification,
    }
  },
}
</script>
